import { alertConstant } from '../constant';
const initialState = {
  payload: null,
  type: '',
  error: '',
};

export default function alertReducers(state = initialState, action) {
  switch (action.type) {
    case alertConstant.NOTIFICATIONSUCCESS:
      return {
        type: action.type,
        payload: action.payload,
        notificationType: action.notificationType,
      };
    case alertConstant.NOTIFICATIONCLEAR:
      return {
        type: action.type,
        payload: null,
        notificationType: null,
      };
    default:
      return state;
  }
}
