import { v4 as uuidv4 } from 'uuid';
import { CreateCampaignConstant } from '../constant';

const initialState = {
  type: '',
  general: null,
  audiences: [],
  segments: [],
  selectedChannels: [],
  openedChannel: '',
  openedCampaign: '',
  openedAudience: '',
  openedSegment: '',
  openedCampaignEndDate: '',
  openedCampaignStartDate: '',
  experienceKeys: {},
};

let tmpAudiences = [];
let tmpSegments = [];
let tmpSegment;
let removedChannelArr = [];
let removedAudienceArr = [];
let removedChannel = null;

export default function createCampaignReducers(state = initialState, action) {
  switch (action.type) {
    case CreateCampaignConstant.ADD_EXPERIENCE_KEYS: {
      return {
        ...state,
        experienceKeys: action.payload,
      };
    }

    case CreateCampaignConstant.ADDAUDIENCE: {
      return {
        ...state,
        audiences: [{ ...action.payload, id: uuidv4() }, ...state.audiences],
      };
    }

    case CreateCampaignConstant.UPDATEAUDIENCE: {
      tmpAudiences = [
        { ...action.payload },
        ...state.audiences.filter((ele) => ele?.id != action?.payload?.id),
      ];
      return {
        ...state,
        audiences: tmpAudiences,
      };
    }

    case CreateCampaignConstant.REMOVEAUDIENCE: {
      return {
        ...state,
        audiences: state.audiences.filter((ele) => ele?.id != action.key),
        segments: state.segments?.filter((ele) => ele?.audience != action?.key),
      };
    }

    case CreateCampaignConstant.ADDSEGMENT: {
      return {
        ...state,
        segments: [{ ...action.payload, id: uuidv4() }, ...state.segments],
      };
    }

    case CreateCampaignConstant.UPDATESEGMENT: {
      const findIndex = state.segments.findIndex(
        (ele) => ele?.id == action?.payload?.id
      );
      const newSegment = [...state.segments];
      if (findIndex > -1) {
        newSegment[findIndex] = {
          ...newSegment[findIndex],
          ...action?.payload,
        };
      }
      return {
        ...state,
        segments: newSegment,
      };
    }

    case CreateCampaignConstant.REMOVESEGMENT: {
      return {
        ...state,
        segments: state.segments.filter((ele) => ele?.id != action.key),
      };
    }

    case CreateCampaignConstant.SETSELECTEDCHANNELS: {
      tmpAudiences = state.audiences;
      tmpSegments = state.segments;
      removedChannelArr = state.selectedChannels?.filter(
        ({ value: chl1 }) =>
          !action.payload.some(({ value: chl2 }) => chl1 === chl2)
      );
      if (removedChannelArr?.length > 0) {
        removedChannel = removedChannelArr[0];
        tmpAudiences = removedChannel
          ? state.audiences?.filter(
              (ele, i) => ele.channel != removedChannel?.value
            )
          : state.audiences;

        removedAudienceArr = state?.audiences?.filter(
          ({ id: audId1 }) =>
            !tmpAudiences.some(({ id: audId2 }) => audId1 === audId2)
        );
        tmpSegments =
          removedChannel && removedAudienceArr?.length > 0
            ? state.segments?.filter(
                ({ audience: audId1 }) =>
                  !removedAudienceArr?.some(
                    ({ id: audId2 }) => audId1 === audId2
                  )
              )
            : state.segments;
      }

      return {
        ...state,
        selectedChannels: action.payload,
        audiences: tmpAudiences,
        segments: tmpSegments,
      };
    }

    case CreateCampaignConstant.SETOPENEDCAMPAIGN: {
      return {
        ...state,
        openedCampaign: action.openedCampaign,
      };
    }

    case CreateCampaignConstant.SETOPENEDCHANNEL: {
      return {
        ...state,
        openedChannel: action.key,
      };
    }

    case CreateCampaignConstant.SETOPENEDAUDIENCE: {
      return {
        ...state,
        openedAudience: action.key,
      };
    }

    case CreateCampaignConstant.SETOPENEDSEGMENT: {
      return {
        ...state,
        openedSegment: action.key,
      };
    }

    case CreateCampaignConstant.SETOPENEDCAMPAIGNENDDATE: {
      return {
        ...state,
        openedCampaignEndDate: action.openedCampaignEndDate,
      };
    }

    case CreateCampaignConstant.SETOPENEDCAMPAIGNSTARTDATE: {
      return {
        ...state,
        openedCampaignStartDate: action.openedCampaignStartDate,
      };
    }

    case CreateCampaignConstant.SAVECONTENTMAPPING: {
      tmpSegment = { ...state.segments.find((seg) => seg.id == action.key) };
      tmpSegments = [
        { ...tmpSegment, ...action.payload },
        ...state.segments.filter((ele) => ele?.id != action?.key),
      ];
      return {
        ...state,
        segments: tmpSegments,
      };
    }

    case CreateCampaignConstant.CLEARALL: {
      return {
        ...state,
        general: null,
        audiences: [],
        segments: [],
        selectedChannels: [],
        openedChannel: '',
        openedCampaign: '',
        openedAudience: '',
        openedSegment: '',
        type: CreateCampaignConstant.CLEARALL,
      };
    }

    case CreateCampaignConstant.SETAUDIENCES: {
      return {
        ...state,
        audiences: action.payload,
      };
    }

    case CreateCampaignConstant.SETSEGMENTS: {
      return {
        ...state,
        segments: action.payload,
      };
    }

    default:
      return state;
  }
}
