export const ConfigurationConstant = {
  DEFAULTERR: 'sorry unable to process! please try again later',

  GETCONFIGURATIONSUCCESS: 'GET_CONFIGURATION_SUCCESS',
  GETCONFIGURATIONFAIL: 'GET_CONFIGURATION_FAIL',

  SAVECONFIGURATIONSUCCESS: 'SAVE_CONFIGURATION_SUCCESS',
  SAVECONFIGURATIONFAIL: 'SAVE_CONFIGURATION_FAIL',
  SET_TYPE_EMPTY: 'SET_TYPE_EMPTY',
  SET_LOADING_TRUE: 'SET_LOADING_TRUE',
  SET_LOADING_FALSE: 'SET_LOADING_FALSE',
  // CHANGECONFIGURATIONSTATUSSUCCESS : "CHANGE_CONFIGURATION_STATUS_SUCCESS",
  // CHANGECONFIGURATIONSTATUSFAIL : "CHANGE_CONFIGURATION_STATUS_FAIL",

  // DELETECONFIGURATIONSUCCESS : "DELETE_CONFIGURATION_SUCCESS",
  // DELETECONFIGURATIONFAIL : "DELETE_CONFIGURATION_FAIL"
};
