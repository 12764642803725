import { ChannelConstant } from '../constant';
const initialState = {
  payload: null,
  type: '',
  message: '',
};

export default function channelReducers(state = initialState, action) {
  switch (action.type) {
    case ChannelConstant.CLEAR_TYPE:
      return {
        ...state,
        type: '',
      };
    case ChannelConstant.GETCHANNELLISTSUCCESS:
      return {
        payload: action.payload,
        type: action.type,
        count: action.count,
        message: action.message,
        description: action.description,
      };
    case ChannelConstant.GETCHANNELLISTFAIL:
      return {
        payload: action.payload,
        type: action.type,
        count: action.count,
        message: action.message,
        description: action.description,
      };
    case ChannelConstant.SAVECHANNELSUCCESS:
      return {
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case ChannelConstant.SAVECHANNELFAIL:
      return {
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case ChannelConstant.CHANGECHANNELSTATUSSUCCESS:
      return {
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case ChannelConstant.CHANGECHANNELSTATUSFAIL:
      return {
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    default:
      return state;
  }
}
