import Lottie from 'lottie-react';
import animation403 from 'assets/Animation/animation403.json';

function Animation403({ sdk }) {
  // const dispatch = useDispatch();
  const { space, environment, app } = sdk.ids;
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          marginTop: '35vh',
          margin: 'auto',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Lottie
          style={{
            height: '20vh',
          }}
          animationData={animation403}
        />
        <div
          style={{
            marginTop: '2rem',
            justifyContent: 'center',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            color: 'rgb(17, 27, 43)',
            fontSize: '1rem',
            fontWeight: 600,
          }}
        >
          Access to this resource on the server is denied!
        </div>
        <div
          style={{
            marginTop: '1rem',
            padding: '1rem',
            display: 'flex',
            fontSize: '1.25rem',
            paddingInline: '5rem',
            width: '15rem',
            color: 'rgb(3, 111, 227)',
            border: '1px solid rgb(3, 111, 227)',
            borderRadius: '5px',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            window.open(
              `https://app.contentful.com/spaces/${space}/environments/${environment}/apps/app_installations/${app}`,
              '_blank'
            );
          }}
        >
          <div
            style={{
              paddingInline: '2rem',
            }}
          >
            Refresh
          </div>
          <i className="fa fa-refresh" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  );
}

export default Animation403;
